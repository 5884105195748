<template>
  <section>
    <div class="richtext">
      <p>
        <img
          src="/uploads/image/20210723/EN_Tank_0.png"
          title="EN_Tank_0.png"
          alt="EN_Tank_0.png"
        />
      </p>
    </div>
  </section>
</template>
<script>
export default {
  title: "Integrated Dosing System",
}
</script>